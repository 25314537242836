import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import api from '../api/api';
import BackgroundHeader from '../components/BackgroundHeader';
import RequestColonyCard from '../components/RequestColonyCard';
//RequestColonyCard
import RequestColonyCardTransfer from '../components/RequestColonyCardTransfer';
import ErrorModal from '../components/ErrorModal';

const RequestedColonyAccess = () => {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [url, setUrl] = useState('');
  const [scoreNow, setScoreNow] = useState(false);
  const [selectColony, setSelectColony] = useState(false);
  const [requestedColonyAccess, setRequestedColonyAccess] = useState();
  const [onComplain, setonComplain] = useState();
  const [selectStation, setSelectStation] = useState();

  const [errorModalFlag, setErrorModalFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };
  const fetchInfo = async () => {
    api
      .get(
        `/user/requested-colony-access/`, 
        {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        // console.log(response.data);
        setRequestedColonyAccess(response.data);
      })
      .catch((error) => {
        console.log(error);
        setErrorModalFlag(true);
        setErrorMsg(error.message);
        // navigate('/Home');
      });
  };

  useEffect(() => {
    fetchInfo();
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="page-body">
      <ErrorModal flag={errorModalFlag} message={errorMsg} />
      <BackgroundHeader />
      <div>
        <Navbar
          displaySidebar={displaySidebar}
          toggleSideBar={toggleSideBar}
          visibilityData={{ visibleModal, setVisibleModal }}
          urlData={{ url, setUrl }}
          scoreNowData={{ scoreNow, setScoreNow }}
          complainData={{ onComplain, setonComplain }}
          stationChange={{ selectStation, setSelectStation }}
          colonyChange={{ selectColony, setSelectColony }}
        />
      </div>
      <div
        style={{
          marginLeft:
            displaySidebar === true
              ? window.innerWidth > 991
                ? '230px'
                : '0px'
              : '0px',
        }}
      >
        <div className="container-fluid">
          {requestedColonyAccess != null && (
            <div>
              <div className="row mb-3">
                <div className="col-12">
                  <h3 className="request-heading">
                    Leave Management for Colony
                  </h3>
                </div>
                {requestedColonyAccess.user_requested.map(function (user, i) {
                  const isAccessColony =
                    user[8] !== null && user[8] === 'Access Colony';
                  return isAccessColony ? (
                    <div
                      key={`request-${i}`}
                      className="col-sm-6 mb-3 order-last"
                    >
                      <RequestColonyCard user={user} />
                    </div>
                  ) : null;
                })}
                {requestedColonyAccess.user_requested.filter(
                  (user) => user[8] === 'Access Colony'
                ).length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      color: 'blue',
                      minHeight: '10vh',
                    }}
                  >
                    No requests for Leave Management.
                  </div>
                )}
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <h3 className="request-heading">
                    Transfer Management for Colony
                  </h3>
                </div>
                {requestedColonyAccess.user_requested.map(function (user, i) {
                  const isChangeColony =
                    user[8] !== null && user[8] === 'Change Home Colony';
                  return isChangeColony ? (
                    <div
                      key={`request-${i}`}
                      className="col-sm-6 mb-3 order-last"
                    >
                      <RequestColonyCardTransfer user={user} />
                    </div>
                  ) : null;
                })}
                {requestedColonyAccess.user_requested.filter(
                  (user) => user[8] === 'Change Home Colony'
                ).length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      color: 'blue',
                      minHeight: '10vh',
                    }}
                  >
                    No requests for Transfer Management.
                  </div>
                )}
              </div>
              <hr />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RequestedColonyAccess;
