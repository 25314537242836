import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import BackgroundHeader from '../components/BackgroundHeader';
import RatingsTable from '../components/RatingsTable';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api';
import {
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CButton,
} from '@coreui/react';
import Loader from '../Loader';
import { formatTaskNumber } from '../utils/formatTaskNumber';
import ColonyRatingsTable from '../components/ColonyTable';

const WriteRatingSpecificDate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  var date = location && location.state.dateParam;
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [selectColony, setSelectColony] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [url, setUrl] = useState('');
  const [scoreNow, setScoreNow] = useState(false);
  const [selectStation, setSelectStation] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [data, setData] = useState();
  const [onComplain, setonComplain] = useState();
  const [nonDialyTask, setNonDailyTask] = useState([]);
  const [taskType, setTaskType] = useState();
  const [weekday, setWeekday] = useState('0');
  const [biweekday, setBiWeekday] = useState('5');
  const [openEnableTask, setOpenEnableTask] = useState(false);
  const [openTaskDetails, setOpenTaskDetails] = useState(false);
  const [verifyBDERatingsModal, setVerifyBDERatingsModal] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState('');
  const [msgclr, setMsgclr] = useState('white');
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [anyTaskAvailability, setAnyTaskAvailability] = useState(false);
  const [waitingForData, setWaitingForData] = useState(true);
  const [waitTimer, setWaitTimer] = useState(0);
  const [colony, setColony] = useState('');
  
  
  const oldStations = [
    '100',
    '101',
    '102',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
  ];

  const genericStations = [
    '111',
    '114',
    '115',
    '116',
    '117',
    '118',
    '119',
    '122',
    '131',
    '132',
    '133',
    '134',
  ];

  const supervisorUserTypes = [
    'supervisor',
    'chi_sm',
    's2 admin',
    'railway admin',
    'officer',
  ];

  const userType = localStorage.getItem('userType');
  const currUserData = JSON.parse(localStorage.getItem('userData'));
  var currUserStation = '';
  var currUserColony = '';
  if (currUserData !== null && currUserData !== undefined) {
    currUserStation =
      currUserData && currUserData.station && currUserData.station.toString();
    currUserColony =
      currUserData && currUserData.colony && currUserData.colony.toString();
  }

  const getStationName = () => {
    const currUserData = JSON.parse(localStorage.getItem('userData'));
    if (currUserData) {
      if (currUserData.station_name) {
        return currUserData.station_name;
      } else if (currUserData.colony_name) {
        return currUserData.colony_name;
      } else {
        return '';
      }
    }
  }

  const handleEnableTasksClick = () => {
    setOpenEnableTask(true);
  };

  const handleModalButtonClick = (str) => {
    if (str === 'W') {
      setTaskType('Weekly');
    } else if (str === 'B') {
      setTaskType('Biannual');
    } else if (str === 'Y') {
      setTaskType('Yearly');
    } else if (str === 'H') {
      setTaskType('Half Yearly');
    } else if (str === 'Q') {
      setTaskType('Quaterly');
    } else if (str === 'F') {
      setTaskType('Fort Nightly');
    } else if (str === 'BW') {
      setTaskType('Biweekly');
    } else if (str === 'A') {
      setTaskType('Alternate');
    } else if (str === 'M') {
      setTaskType('Monthly');
    }

    var b_tasks = [];
    for (let i = 0; i < data.task_A.length; i++) {
      if (data.task_A[i][5] === str) {
        b_tasks.push(data.task_A[i]);

        const filteredArrays = data.task_shift_occurs.filter(
          (arr) => arr[6] === data.task_A[i][0]
        );
        const enabledTask = filteredArrays.some((arr) => arr[1] === true);
        if (enabledTask) {
          b_tasks[b_tasks.length - 1].push('true');
        }

        console.log(filteredArrays);
        console.log(data.task_A[i]);

        // for (let j = 0; j < 3; j++) {
        //   if (
        //     data.occurrence_list_A[data.task_A[i][1] - 1][j] !== 'NIL' &&
        //     data.occurrence_list_A[data.task_A[i][1] - 1][j] !== undefined
        //   ) {
        //     b_tasks[b_tasks.length - 1].push('true');
        //   }
        // }
      }
    }
    for (let i = 0; i < data.task_B.length; i++) {
      if (data.task_B[i][5] === str) {
        b_tasks.push(data.task_B[i]);
        // b_tasks.push(data.task_B[i]);
        const filteredArrays = data.task_shift_occurs.filter(
          (arr) => arr[6] === data.task_B[i][0]
        );
        const enabledTask = filteredArrays.some((arr) => arr[1] === true);
        if (enabledTask) {
          b_tasks[b_tasks.length - 1].push('true');
        }
        // for (let j = 0; j < 3; j++) {
        //   if (
        //     data.occurrence_list_B[data.task_B[i][1] - data.task_A.length - 1][
        //       j
        //     ] !== 'NIL'
        //   ) {
        //     b_tasks[b_tasks.length - 1].push('true');
        //   }
        // }
      }
    }
    for (var i = 0; i < data.task_C.length; i++) {
      if (data.task_C[i][5] === str) {
        b_tasks.push(data.task_C[i]);
        const filteredArrays = data.task_shift_occurs.filter(
          (arr) => arr[6] === data.task_C[i][0]
        );
        const enabledTask = filteredArrays.some((arr) => arr[1] === true);
        if (enabledTask) {
          b_tasks[b_tasks.length - 1].push('true');
        }
        // for (var j = 0; j < 3; j++) {
        //   if (
        //     data.occurrence_list_C[
        //       data.task_C[i][1] - (data.task_A.length + data.task_B.length + 1)
        //     ][j] !== 'NIL'
        //   ) {
        //     b_tasks[b_tasks.length - 1].push('true');
        //   }
        // }
      }
    }
    // console.log(b_tasks);
    setNonDailyTask(b_tasks);
    console.log("Hello")
    console.log(b_tasks)
    setOpenTaskDetails(true);
  };

  const get_weekday = (num) => {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    return days[parseInt(num)];
  };

  // NOTE: will be enabled when the tetsing is done for new contract.
  // const  = useCallback(() => {
  //   const currentDate = new Date();
  //   const selectedDate = new Date(date);

  //   if (selectedDate > currentDate) {
  //     setShowLoader(false);
  //     setShowPopup(true);
  //     setmessage('date may not be greater than current date');
  //     setTimeout(() => {
  //       setShowPopup(false);
  //       navigate('/');
  //     }, 3000);
  //     return;
  //   }
  // }, [date, navigate]);

  const disableTask = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-tasks/', {
        action: 'D',
        task_id: task_id,
        date: data.date,
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };
  
  const disableColonyTask = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-colony-tasks/', {
        action: 'D',
        task_id: task_id,
        date: data.date,
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all_colony/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };

  const enableTask = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-tasks/', {
        action: 'E',
        task_id: task_id,
        date: data.date,
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };
  
  const enableColonyTask = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-colony-tasks/', {
        action: 'E',
        task_id: task_id,
        date: data.date,
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all_colony/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };

  const enableTaskWeekly = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-tasks/', {
        action: 'E',
        task_id: task_id,
        date: data.date,
        day: weekday,
        bwday: '1',
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };

  const enableColonyTaskWeekly = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-colony-tasks/', {
        action: 'E',
        task_id: task_id,
        date: data.date,
        day: weekday,
        bwday: '1',
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all_colony/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };

  const enableTaskBiWeekly = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-tasks/', {
        action: 'E',
        task_id: task_id,
        date: data.date,
        day: biweekday,
        bwday: '2',
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };
  
  const enableColonyTaskBiWeekly = (e, task_id) => {
    e.preventDefault();
    api
      .post('/ratings/enable-colony-tasks/', {
        action: 'E',
        task_id: task_id,
        date: data.date,
        day: biweekday,
        bwday: '2',
      })
      .then((response) => {
        setOpenTaskDetails(false);
        fetchInfo('/ratings/all_colony/');
        if (response.data.message) {
          alert(response.data.message);
        }
      });
  };

  const fetchInfo = useCallback(
    async (fetcInfoUrl) => {
      setShowLoader(true);
      setWaitTimer(120);
      setData(null);
      api
        .post(
          fetcInfoUrl,
          { date: date },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': '{{ csrf_token }}',
            },
          }
        )
        .then((response) => {
          setShowLoader(false);
          let shiftCode;
          if (oldStations.includes(currUserStation)) {
            setShowVerifyButton(
              response.data.verified_shifts[0] ||
                response.data.verified_shifts[1] ||
                response.data.verified_shifts[2]
            );
            shiftCode = response.data.shift[1][1];
          } else if (genericStations.includes(currUserStation)) {
            setShowVerifyButton(
              response.data.verified_shifts[0] || 
              response.data.verified_shifts[1] ||
              response.data.verified_shifts[2]
            );
            shiftCode = response.data.shift[0][1];
          } else {
            setShowVerifyButton(response.data.verified_shifts[0] || 
              response.data.verified_shifts[1] ||
              response.data.verified_shifts[2]);
            shiftCode = response.data.shift[0][1];
          }
          if (colony && colony !== '[null]' && colony !== '[undefined]') {
            setShowVerifyButton(
              response.data.verified_shifts[0] ||
                response.data.verified_shifts[1] ||
                response.data.verified_shifts[2]
            );
            shiftCode = response.data.shift[1][1];
          }
          localStorage.setItem('shiftCode', shiftCode);
          setData(response.data);
          const all_tasks = response.data && [
            ...response.data.task_A,
            ...response.data.task_B,
            ...response.data.task_B,
          ];
          if (all_tasks && all_tasks.length > 0) {
            setAnyTaskAvailability(true);
          } else {
            setAnyTaskAvailability(false);
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error);
        });
    },
    [date, currUserStation]
  );

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData !== undefined && userData !== null) {
      if (userData.station !== null && userData.station !== undefined) {
        const fetcInfoUrl = `/ratings/all/`;
        fetchInfo(fetcInfoUrl);
      } else if (userData.colony !== null && userData.colony !== undefined) {
        const colonyName = userData.colony_name;
        setColony(`[${colonyName}]`);
        const fetcInfoUrl = `/ratings/all_colony/`;
        fetchInfo(fetcInfoUrl);
      } else {
        return;
      }
    }
  }, [fetchInfo]);

  useEffect(() => {
    if (waitTimer > 0) {
      setWaitingForData(true);
    } else {
      setWaitingForData(false);
    }
  }, [waitTimer]);

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const verifyBDEStationsRatingsDirectly = () => {
    setVerifyMsg('Verifying Ratings..');
    api
      .post(
        `/ratings/verify_ratings_bde_supervisor`,
        {
          date: data.date,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        }
      )
      .then(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData !== undefined && userData !== null) {
          if (userData.station !== null && userData.station !== undefined) {
            const fetcInfoUrl = `/ratings/all/`;
            fetchInfo(fetcInfoUrl);
            setVerifyBDERatingsModal(false);
          } else if (
            userData.colony !== null &&
            userData.colony !== undefined
          ) {
            const colonyName = userData.colony_name;
            setColony(`[${colonyName}]`);
            const fetcInfoUrl = `/ratings/all_colony/`;
            fetchInfo(fetcInfoUrl);
            setVerifyBDERatingsModal(false);
          } else {
            setVerifyBDERatingsModal(false);
            return;
          }
        }
      })
      .catch((error) => {
        console.log('Error', error);
        setVerifyMsg(error.response.data.message);
        setTimeout(() => {
          setVerifyMsg('');
          setVerifyBDERatingsModal(false);
        }, 2000);
        if (error.response) {
          setVerifyMsg(error.response.data.message);
        } else {
          setVerifyMsg(error.message);
        }
        setMsgclr('#ccf1fd');
      });
  };

  const verifyRatingsBtn = () => {
    let userIsLoggedInAt = '';
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData !== null && userData !== undefined) {
      if (
        userData.station_name !== null &&
        userData.station_name !== undefined
      ) {
        const station = userData.station_name;
        const stationCategory = userData.station_category;

        switch (stationCategory) {
          case 'A1':
            userIsLoggedInAt = `A1 - category station ${station}`;
            break;
          case 'A':
            userIsLoggedInAt = `A - category station ${station}`;
            break;
          case 'B':
          case 'D':
          case 'E':
            userIsLoggedInAt = `BDE - category station ${station}`;
            break;
          default:
            setVerifyMsg(
              'Invalid Station Category, Please re-login to try again'
            );
            setMsgclr('#f81504');
            return;
        }
      } else if (
        userData.colony_name !== null &&
        userData.colony_name !== undefined
      ) {
        userIsLoggedInAt = `colony - ${userData.colony_name}`;
      }
    }

    if (userIsLoggedInAt === '') {
      setVerifyMsg('Invalid login, Please re-login to try again');
      setMsgclr('#f81504');
      return;
    }

    if (userIsLoggedInAt.includes('station')) {
      switch (true) {
        case userIsLoggedInAt.includes('A1 - category station'):
          console.log(
            'Navigate to verify ratings page to verify ratings for A1 category station'
          );
          navigate('/verify-ratings', { replace: true });
          break;
        case userIsLoggedInAt.includes('A - category station'):
          console.log(
            'Navigate to verify ratings page to verify ratings for A category station'
          );
          navigate('/verify-ratings', { replace: true });
          break;
        case userIsLoggedInAt.includes('BDE - category station'):
          console.log('Initiate BDE direct verification...');
          if (supervisorUserTypes.includes(userType)) {
            if (data.is_pending_tasks) {
              setVerifyMsg(`Tasks are not completed yet, Please complete to verify `);
              setMsgclr('#f81504');
              return;
            }
            if (data.verified_shifts[0] === true || data.verified_shifts[1] === true || data.verified_shifts[2] === true) {
              setVerifyMsg(`shift already verified`);
              setMsgclr('#f81504');
              return;
            } else {
              setVerifyBDERatingsModal(true);
            }
          } else {
            navigate('/verify-ratings', { replace: true });
          }
          break;
        default:
          setVerifyMsg('Invalid Station, Please re-login to try again');
          setMsgclr('#f81504');
          break;
      }
    } else if (userIsLoggedInAt.includes('colony')) {
      console.log(
        'Navigate to verify ratings page to verify ratings for colony'
      );
      navigate('/verify-ratings-colonies', { replace: true });
    } else {
      setVerifyMsg('Invalid login, Please re-login to try again');
      setMsgclr('#f81504');
    }
  };

  return (
    <React.Fragment>
      <div className="loader">{showLoader && <Loader></Loader>}</div>
      <div className="page-body pt-3">
        <Navbar
          displaySidebar={displaySidebar}
          toggleSideBar={toggleSideBar}
          visibilityData={{ visibleModal, setVisibleModal }}
          urlData={{ url, setUrl }}
          scoreNowData={{ scoreNow, setScoreNow }}
          complainData={{ onComplain, setonComplain }}
          stationChange={{ selectStation, setSelectStation }}
          colonyChange={{ selectColony, setSelectColony }}
          navDate={date}
        />

        <div
          style={{
            marginLeft:
              displaySidebar === true
                ? window.innerWidth > 991
                  ? '230px'
                  : '0px'
                : '0px',
          }}
        >
          <BackgroundHeader
            heading="Daily Buyer's rating"
            subheading="Daily Buyer's rating"
            displaySidebar={displaySidebar}
          />

          {anyTaskAvailability ? (
            <React.Fragment>
            {currUserStation && <RatingsTable data={data} />}
            {currUserColony && <ColonyRatingsTable data={data} />}
            {/* 
              <React.Fragment>
                {oldStations.includes(currUserStation) ? (
                ) : genericStations.includes(currUserStation) ? (
                  <GenricRatingsTable data={data} />
                ) : (
                  <BdeRatingsTable data={data} />
                )}
              </React.Fragment>
            */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {waitingForData ? (
                <Loader />
              ) : (
                <div className="min-h-screen flex justify-center items-center text-center">
                  There are no tasks available for the selected station under
                  the alloted contract.
                </div>
              )}
            </React.Fragment>
          )}

          {anyTaskAvailability && data !== null && data !== undefined && (
            <React.Fragment>
              {supervisorUserTypes.includes(userType) && (
                <button
                  type="button"
                  className="btn btn-primary btn-lg enableTaskBtns1 rounded"
                  onClick={handleEnableTasksClick}
                >
                  Enable Non-Daily Tasks
                </button>
              )}

              {data.is_pending_tasks ? (
                <button
                  type="button"
                  className="btn btn-danger btn-lg enableTaskBtns1 rounded"
                  onClick={verifyRatingsBtn}
                  disabled={true}
                >
                  Verify Ratings
                </button>
              ) : (
                <React.Fragment>
                  {showVerifyButton ? (
                    <button
                      type="button"
                      className="btn btn-success btn-lg enableTaskBtns1 rounded"
                      onClick={verifyRatingsBtn}
                      disabled
                    >
                      Verify Ratings
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary btn-lg enableTaskBtns1 rounded"
                      onClick={verifyRatingsBtn}
                    >
                      Verify Ratings
                    </button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>

        {/* B, D and E  categrory station for supervisor and CHI_SM verfiy digital signature */}
        <CModal
          visible={verifyBDERatingsModal}
          onClose={() => {
            setVerifyBDERatingsModal(false);
          }}
          aria-labelledby="BDEVerifyRatingsModal"
        >
          <CModalHeader
            onClose={() => {
              setVerifyBDERatingsModal(false);
            }}
          >
            <CModalTitle id="BDEVerifyRatingsModal">Verify Ratings</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {data !== null && data !== undefined && (
              <div>
                <div>Do you wish to verify Ratings for {getStationName()}</div>
                <div className="flex flex-row justify-between pt-8  px-8">
                  <button
                    className="btn btn-primary inline"
                    onClick={verifyBDEStationsRatingsDirectly}
                  >
                    Verify Ratings
                  </button>
                  <button
                    className="btn btn-danger inline"
                    onClick={() => {
                      setVerifyBDERatingsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="verify-msg" style={{ backgroundColor: msgclr }}>
                  {verifyMsg}
                </div>
              </div>
            )}
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVerifyBDERatingsModal(false);
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>

         {/* Buttons for Enabling and DIsabling Non-Daily Tasks */}
        <CModal
          visible={openEnableTask}
          onClose={() => {
            setOpenEnableTask(false);
          }}
          aria-labelledby="ScoreNow"
        >
          <CModalHeader
            onClose={() => {
              setOpenEnableTask(false);
            }}
          >
            <CModalTitle id="LiveDemoExampleLabel">Enable Tasks</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="container text-center">
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('A')}
                >
                  Change Alternate Day
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('BW')}
                >
                  Change Biweekly Days
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('W')}
                >
                  Change Weekly Day
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('F')}
                >
                  Change Fortnight Day
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('M')}
                >
                  Enable Monthly Tasks
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('Q')}
                >
                  Enable Quarterly Tasks
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('H')}
                >
                  Enable Half Yearly Tasks
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('B')}
                >
                  Enable Bianually Tasks
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block enableTaskBtns"
                  onClick={() => handleModalButtonClick('Y')}
                >
                  Enable Yearly Tasks
                </button>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setOpenEnableTask(false);
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>

        <CModal
          visible={openTaskDetails}
          onClose={() => {
            setOpenTaskDetails(false);
          }}
          aria-labelledby="ScoreNow"
        >
          <CModalHeader
            onClose={() => {
              setOpenTaskDetails(false);
            }}
          >
            <CModalTitle id="LiveDemoExampleLabel">
              {taskType} Tasks
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="table-responsive">
              {nonDialyTask.length !== 0 ? (
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Task No.</th>
                      <th scope="col">Task Name</th>
                      <th scope="col">Type</th>
                      {taskType !== 'Weekly' &&
                      taskType !== 'Biweekly' &&
                      taskType !== 'Fort Nightly' &&
                      taskType !== 'Alternate' ? (
                        <React.Fragment>
                          <th scope="col">Last Enabled</th>
                          <th scope="col">Next Expected Cycle</th>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {taskType !== 'Alternate' ? (
                            <th scope="col">Weekday</th>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </React.Fragment>
                      )}

                      {taskType === 'Biweekly' ? (
                        <React.Fragment>
                          <th scope="col">Weekday 2</th>
                        </React.Fragment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}

                      <th scope="col">Today's Status</th>

                      {taskType === 'Alternate' ? (
                        <th scope="col">Next Expected Cycle</th>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {nonDialyTask.map(function (task, i) {
                      return (
                        <tr>
                          <th scope="row">{formatTaskNumber(task[1])}</th>
                          <td>{task[2]}</td>
                          <td>{taskType}</td>
                          {taskType !== 'Weekly' &&
                          taskType !== 'Biweekly' &&
                          taskType !== 'Fort Nightly' &&
                          taskType !== 'Alternate' ? (
                            <React.Fragment>
                              <td>{data.cycles[task[1]].last_enabled}</td>
                              <td>{data.cycles[task[1]].next_cycle}</td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {taskType !== 'Alternate' && (
                                <td>
                                  <p>{get_weekday(task[13])}</p>
                                  <select
                                    name="day"
                                    class="form-select form-control"
                                    aria-label="Default select example"
                                    style={{ height: '37px' }}
                                    onChange={(e) => setWeekday(e.target.value)}
                                  >
                                    <option value="0">Monday</option>
                                    <option value="1">Tuesday</option>
                                    <option value="2">Wednesday</option>
                                    <option value="3">Thursday</option>
                                    <option value="4">Friday</option>
                                    <option value="5">Saturday</option>
                                    <option value="6">Sunday</option>
                                  </select>
                                  <form
                                    onSubmit={(e) => {
                                      if (currUserStation) {
                                        enableTaskWeekly(e, task[1]);
                                      } else if (currUserColony) {
                                        enableColonyTaskWeekly(e, task[1]);
                                      } else {
                                        enableTaskWeekly(e, task[1]);
                                      }
                                    }}
                                  >
                                    <button
                                      type="submit"
                                      className="btn btn-success"
                                    >
                                      Update
                                    </button>
                                  </form>
                                </td>
                              )}
                            </React.Fragment>
                          )}
                          {taskType === 'Biweekly' && (
                            <React.Fragment>
                              <td>
                                <p>{get_weekday(task[14])}</p>
                                <select
                                  name="day"
                                  class="form-select form-control"
                                  aria-label="Default select example"
                                  style={{ height: '37px' }}
                                  onChange={(e) => setBiWeekday(e.target.value)}
                                >
                                  <option value="0">Monday</option>
                                  <option value="1">Tuesday</option>
                                  <option value="2">Wednesday</option>
                                  <option value="3">Thursday</option>
                                  <option value="4">Friday</option>
                                  <option value="5">Saturday</option>
                                  <option value="6">Sunday</option>
                                </select>
                                <form
                                onSubmit={(e) => {
                                  if (currUserStation) {
                                    enableTaskBiWeekly(e, task[1])
                                  } else if (currUserColony) {
                                    enableColonyTaskBiWeekly(e, task[1])
                                  } else {
                                    enableTaskBiWeekly(e, task[1])
                                  }
                                }}
                                  
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    Update
                                  </button>
                                </form>
                              </td>
                            </React.Fragment>
                          )}
                          <td>
                            {task[21] ? (
                              <React.Fragment>
                                <h5 style={{ color: 'rgb(13, 219, 13)' }}>
                                  ENABLED
                                </h5>
                                {taskType !== 'Weekly' &&
                                  taskType !== 'Biweekly' &&
                                  taskType !== 'Fort Nightly' && (
                                    <form
                                    onSubmit={(e) => {
                                      if (currUserStation) {
                                        disableTask(e, task[1])
                                      } else if (currUserColony) {
                                        disableColonyTask(e, task[1])
                                      } else {
                                        disableTask(e, task[1])
                                      }
                                    }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-danger"
                                      >
                                        DISABLE
                                      </button>
                                    </form>
                                  )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <h5 style={{ color: 'red' }}>DISABLED</h5>
                                {taskType !== 'Weekly' &&
                                  taskType !== 'Biweekly' &&
                                  taskType !== 'Fort Nightly' && (
                                    <form
                                    onSubmit={(e) => {
                                      if (currUserStation) {
                                        enableTask(e, task[1])
                                      } else if (currUserColony) {
                                        enableColonyTask(e, task[1])
                                      } else {
                                        enableTask(e, task[1])
                                      }
                                    }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                      >
                                        ENABLE
                                      </button>
                                    </form>
                                  )}
                              </React.Fragment>
                            )}
                          </td>

                          {taskType === 'Alternate' && (
                            <React.Fragment>
                              {task[21] ? (
                                // eslint-disable-next-line jsx-a11y/scope
                                <td scope="col">Day After Tomorrow</td>
                              ) : (
                                // eslint-disable-next-line jsx-a11y/scope
                                <td scope="col">Tomorrow</td>
                              )}
                            </React.Fragment>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <h4>
                  There are no {taskType} Tasks in {currUserStation}
                </h4>
              )}
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setOpenTaskDetails(false);
              }}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    </React.Fragment>
  );
};

export default WriteRatingSpecificDate;
